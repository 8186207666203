<script>
import { Line } from "vue-chartjs";
export default {
  extends: Line,
  props: {
    plot1ChartData: {
      type: Array,
      required: false,
    },
    plot1Title: {
      type: String,
      required: false,
    },
    plot2ChartData: {
      type: Array,
      required: false,
    },
    plot2Title: {
      type: String,
      required: false,
    },
  },
  watch: {
    plot1ChartData: function () {
      this.renderLineChart();
    },
    plot2ChartData: function () {
      this.renderLineChart();
    },
  },
  data() {
    return {
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              type: "time",
              distribution: "linear",
              time: {
                displayFormats: {
                  minute: "HH:mm",
                },
              },
            },
          ],
        },
        legend: {
          display: true,
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.renderLineChart();
  },
  methods: {
    renderLineChart: function () {
      this.renderChart(
        {
          datasets: [
            {
              label: this.plot1Title,
              borderColor: "#249E00",
              pointBackgroundColor: "#249E00",
              pointBorderColor: "#249EBF",
              borderWidth: 1,
              backgroundColor: "transparent",
              data: this.plot1ChartData,
            },
            {
              label: this.plot2Title,
              borderColor: "#209EBF",
              pointBackgroundColor: "#209EBF",
              pointBorderColor: "#209EBF",
              borderWidth: 1,
              backgroundColor: "transparent",
              data: this.plot2ChartData,
            },
          ],
        },
        this.options
      );
    },
  },
};
</script>